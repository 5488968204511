import Titulo from "../Titulo"

const Formacoes = () => {
    return (
<>
<section className="container mx-auto py-12">
  <h2 className="text-xl font-bold text-gray-800">Formações</h2>

  <ul className="list-disc mt-4">
    <li className="text-lg text-gray-600">
      Graduação em Gestão de Recursos Humanos
      <br />
      UAM - Universidade Anhembi Morumbi
      <br />
      Formado em 2023
    </li>
    <li className="text-lg text-gray-600">
      Especialização em Saúde Mental e Desenvolvimento Humano
      <br />
      PUCPR - Pontifícia Universidade Católica do Paraná
      <br />
      Formado em 2024
    </li>
    <li className="text-lg text-gray-600">
      Bacharelado em Direito
      <br />
      FAM - Faculdade de Americana
      <br />
      Cursando
    </li>
    <li className="text-lg text-gray-600">
      Especialização em Direitos Humanos, Responsabilidade Social e Cidadania Global
      <br />
      PUCRS - Pontifícia Universidade Católica do Rio Grande do Sul
      <br />
      Cursando
    </li>
    <li className="text-lg text-gray-600">
      Bacharelado em Psicologia
      <br />
      Centro Universitário Salesiano de São Paulo
      <br />
      Trancado
    </li>
  </ul>

  <h2 className="text-xl font-bold text-gray-800 mt-12">Principais Certificações</h2>

  <ul className="list-disc mt-4">
    <li className="text-lg text-gray-600">
      Formação em Psicanálise
      <br />
      Sociedade Psicanalítica Sigmund Freud de São Paulo
      <br />
      2021
    </li>
    <li className="text-lg text-gray-600">
      Curso Nacional de Saúde Mental e Atenção Psicossocial: Reconstrução Pós-desastres e Emergências em Saúde Pública
      <br />
      FIOCRUZ
      <br />
      2022
    </li>
    <li className="text-lg text-gray-600">
      Comportamento Suicida: Avaliação e Manejo
      <br />
      Artmed 360 – Dr Neury José Botega
      <br />
      2022
    </li>
    <li className="text-lg text-gray-600">
      Prevenção ao Suicídio
      <br />
      UFSC - Universidade Federal de Santa Catarina
      <br />
      2021
    </li>
    <li className="text-lg text-gray-600">
      Liderança, Inteligência Interpessoal e Comunicação eficaz
      <br />
      The Napoleon Hill Foundation
      <br />
      2017
    </li>
  </ul>
</section>
</>

    )
}

export default Formacoes