import Titulo from "../Titulo"

const Sobre = () => {
    return (
    <>
    <section class="mb-12">
      <h2 class="text-xl font-bold text-gray-800">Sobre Guilherme Bernardino</h2>
      <p class="text-lg text-gray-600 mt-4">
        Guilherme acredita que cada indivíduo possui o potencial para superar desafios, alcançar a plenitude e viver uma vida mais autêntica e significativa. E através da psicanálise, busca empoderar seus pacientes para que assumam as rédeas de suas vidas.
      </p>
    </section>

    <section class="mb-12">
      <h2 class="text-xl font-bold text-gray-800">Experiência profissional</h2>
      <ul class="list-disc mt-4">
        <li class="text-lg text-gray-600">
          Possui experiência em cargos de gestão de relacionamento e liderança, tendo atuado como executivo em empresas de relevância nacional.
        </li>
        <li class="text-lg text-gray-600">
          Atualmente, atua em uma Universidade local e também atende como psicanalista.
        </li>
        <li class="text-lg text-gray-600">
          Na iniciativa pública, no âmbito do controle social, já exerceu mandato como Conselheiro de Saúde, Conselheiro de Segurança Alimentar e também atuou no Comitê de Prevenção ao Óbito Materno-infantil do Hospital Municipal de Americana.
        </li>
        <li class="text-lg text-gray-600">
          Em 2023, exerceu voz e voto na 9ª Conferência Estadual de Saúde de São Paulo, sendo um dos representantes dos gestores e prestadores de saúde da região de Campinas.
        </li>
      </ul>
    </section>

    <section>
      <h2 class="text-xl font-bold text-gray-800">Ativismo e ideais</h2>
      <p class="text-lg text-gray-600 mt-4">
        Bernardino tem se dedicado a promover a saúde e bem-estar, ativista Pró-Direitos Humanos que idealiza uma sociedade mais saudável, justa e equilibrada.
      </p>
    </section>
    </>
        )
}


export default Sobre