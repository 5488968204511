import React from 'react';

const BarraLateral = () => {
  return (
<aside className='bg-gray-200 p-3 mb-3 xl:w-1/4 h-fit'>
  <table className="w-full" itemScope itemType="http://schema.org/Person">
    <thead>
      <tr>
        <th className='text-center bg-gray-300 text-lg' colSpan="2" itemProp="name">
          <span>Guilherme Bernardino</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colSpan="2" className="text-center">
          <img src="/guilhermebernadino.jpeg" alt="Guilherme Bernardino" className="mx-auto my-4 w-64 h-auto" />
        </td>
      </tr>
      <tr>
        <th className='text-center bg-gray-300 text-lg' colSpan="2">
          <span>Informações Gerais</span>
        </th>
      </tr>
      <tr>
        <td className="font-semibold">Nome completo</td>
        <td itemProp="name">Guilherme Aparecido Bernardino da Silva</td>
      </tr>
      <tr>
        <td className="font-semibold">Nascimento</td>
        <td>
          <time itemProp="birthDate" dateTime="1997-04-23">23 de Abril de 1997</time>
        </td>
      </tr>
      <tr>
        <td className="font-semibold">Nacionalidade</td>
        <td itemProp="nationality">Brasileiro</td>
      </tr>
      <tr>
        <td className="font-semibold">Local de nascimento</td>
        <td itemProp="birthPlace">Santa Cruz do Rio Pardo, São Paulo </td>
      </tr>
     
    
      <tr>
        <td className="font-semibold">Redes Sociais</td>
        <td>
          <span itemProp="sameAs" itemScope><a href="https://www.instagram.com/guibernardino.b/" target="_blank"  className='text-blue-500 hover:text-blue-400'>Instagram
          </a></span><br /> 
           <span itemProp="sameAs" itemScope><a href="https://twitter.com/gbernardino97" target="_blank"  className='text-blue-500 hover:text-blue-400'> twitter
          </a></span><br />          
          <span itemProp="sameAs" itemScope><a href="https://www.facebook.com/1bernardino" target="_blank"  className='text-blue-500 hover:text-blue-400'>Facebook
          </a></span><br />          
          <span itemProp="sameAs" itemScope><a href="https://www.linkedin.com/in/guilherme-bernardino" target="_blank"  className='text-blue-500 hover:text-blue-400'>LinkedIn
          </a></span><br />          
          <span itemProp="sameAs" itemScope><a href="https://guilhermebernadino.com.br/" target="_blank"  className='text-blue-500 hover:text-blue-400'>Site Oficial
          </a></span><br />     
          <span itemProp='sameAs' itemScope><a target="_blank"  className='text-blue-500 hover:text-blue-400' href='https://www.google.com/search?kgmid=/g/'>Painel de informações do Google</a></span>
             
        </td>
      </tr>
     
    </tbody>
  </table>
</aside>


  );
};

export default BarraLateral;

